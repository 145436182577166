import PropTypes from "prop-types"
import React from "react"

const SectionWrapper = props => (
  <div className={props.primary ? "bg-primary" : "bg-white"}>
    <div
      className={[
        "sticky w-100 z-60 h-rem-20 xs:h-rem-14 sm:h-rem-14 md:h-rem-16 t-0 l-0",
        props.primary ? "bg-primary" : "bg-white",
      ].join(" ")}
    />
    <div className="min-h-screen text-accent relative z-50 relative">
      <div
        className={[
          "w-90 mx-auto",
          props.padding ? "py-32 xs:py-16" : null,
        ].join(" ")}
      >
        <div
          id={props.id}
          className={[props.padding ? "xs:py-0 xl:py-16" : null]}
        >
          {props.children}
        </div>
      </div>
    </div>
  </div>
)

SectionWrapper.propTypes = {
  primary: PropTypes.bool,
  padding: PropTypes.bool,
  id: PropTypes.string,
}

SectionWrapper.defaultProps = {
  primary: false,
  padding: true,
  id: null,
}

export default SectionWrapper
